import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { TabItem as TabItemBase } from '@components/common/styles';
import { breakpoint } from '@src/settings';

export const TabItem = styled(TabItemBase)`
	${ media.lessThan(`${ breakpoint.sp }px`)`
		& > span{
			&:nth-child(1){
				display: inherit;
			}
		}
	`};
`;

