import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { graphql, StaticQuery} from 'gatsby';
import Image from 'gatsby-image';
import Hero from '@components/en/common/hero';
import ContactIntro from '@components/en/common/contact-intro';
import { HeroTitle } from '@components/company/svgs';
import Table from '@components/company/table';
import Layout from '@components/common/layout';
import { Tab } from '@components/common/styles';
import { TabItem } from '@components/en/common/styles';
import { color, mixin, breakpoint } from '@src/settings';

const Company_page = ({ data }) => (
	<Layout baseBgColor={ color.paleGray }>
		<main>
			<Hero
				img={ data['heroImg'] }
				title={<HeroTitle style={{ fill:"#FFF" }}/>}
				subTitle="会社概要・沿革"
			/>
			<Tab>
				<TabItem to="/en/message"><span>Message</span></TabItem>
				<TabItem to="/en/company" className="isActive"><span>Company</span></TabItem>
			</Tab>
			<Container>
				<Table
					className="table"
					title="Company Overview"
					list={[
						{
							title:<>PHILOSOPHY</>,
							data:"TECHNOLOGY DRIVES TOMORROW"
						},
						{
							title: "VISION",
							data: <>
								<p>Aim to be a Manufacturer for Technology Components with Global Competitiveness to support our National Industry</p>
							</>
						},
						{
							title:<>MISSION</>,
							data: <>
								<p><b>L</b>ong term relationship with customers, suppliers, and employees by creating triple win situation based on integrity</p>
								<p><b>S</b>ocial Responsibility being carried out by creating sustainable working environment and continually contribute to the society</p>
								<p><b>F</b>ulfilment of customer satisfaction being prioritized and achieved by constantly striving for excellence</p>
							</>
						},
						{
							title:<>OUR CORE VALUE</>,
							data: <>
								<p><b>C</b>ustomer First</p>
								<p><b>E</b>xcellence Kaizen</p>
								<p><b>R</b>espect</p>
								<p><b>I</b>ntegrity</p>
								<p><b>A</b>ccountability</p>
							</>
						},
					]}
				/>
				<Table 
					className="table"
					title="Company Information"
					list={[
						{
							title:<>Location</>,
							data: <>
								<p>Lot 5747, Kidamat Industrial Park, <br/>Bukit Angkat, <br />43000 Kajang, Selangor Darul Ehsan, Malaysia</p>
								<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15937.407800463345!2d101.7622671!3d2.9995782!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x88477d01432f139b!2sLSF%20Technology%20Sdn%20Bhd!5e0!3m2!1sja!2sjp!4v1585289745680!5m2!1sja!2sjp" width="600" height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
							</>
						},
						{
							title:<>Establishment</>,
							data:"April 2000"
						},
						{
							title:<>Capital</>,
							data:"26.5 million Ringgit Malaysia"
						},
						{
							title:"Managing Director",
							data:"Dato' Stan Lee Chan Fai"
						},
						{
							title:<>Number of Employees</>,
							data:"447 People(As of November 2019)"
						},
					]}
				/>
				<Table 
					className="table"
					history
					title="Corporate History"
					list={[
						{
							title:<>April<br />2000</>,
							data:"Establishment of LSF Technology"
						},
						{
							title:<>January<br />2002</>,
							data:"Commencement of CO2 robotic welding, started manufacturing of Assembly Parts"
						},
						{
							title:<>January<br />2004</>,
							data:"Certified with ISO 9001"
						},
						{
							title:<>April<br />2007</>,
							data:"Started business with Toyota Auto Body Malaysia (TABM) as Tier-1 supplier"
						},
						{
							title:<>July<br />2007</>,
							data:"Commencement of large CNC machine. Started B-Class tooling manufacturing"
						},
						{
							title:<>May<br />2009</>,
							data:"Addition of two lines of 160 Ton class press to support production expansion."
						},
						{
							title:<>April<br />2010</>,
							data:"Commencement of 5-Axis laser cutting machine to further expand Prototype Department."
						},
						{
							title:<>March<br />2011</>,
							data:"Relocation of LSF Headquarters and factory to Kajang, Selangor. It is built on a 30,000 square meters of land."
						},
						{
							title:<>January<br />2012</>,
							data:"Commencement of 1000 Ton trial press machine."
						},
						{
							title:<>June<br />2012</>,
							data:"Started business with Perodua (PMSB) as Tier-1 supplier"
						},
						{
							title:<>January<br />2013</>,
							data:"Certified with ISO 14001"
						},
						{
							title:<>September<br />2014</>,
							data:"Establishment of 2nd plant at Kajang HQ for operational expansion"
						},
						{
							title:<>November<br />2014</>,
							data:"Commencement of 500 Ton class press line. Started B-Class press parts manufacturing."
						},
						{
							title:<>April<br />2015</>,
							data:"Started business with Proton as Tier-1 supplier"
						},
						{
							title:<>July<br />2015</>,
							data:"Inauguration of new Managing Director, Dato' Stan Lee"
						},
						{
							title:<>December<br />2015</>,
							data:"Commencement of 2000 Ton Hydraulic Press. Addition of three laser cutting machines. Started Outer Panel parts manufacturing."
						},
						{
							title:<>November<br />2016</>,
							data:"Commencement of 600 Ton class Automate Press Line to support production expansion."
						},
						{
							title:<>April<br />2017</>,
							data:"Certified with TS 16949"
						},
						{
							title:<>April<br />2018</>,
							data:"Commencement of five Welding Robots to support production of Rear Under parts."
						},
					]}
				/>
			</Container>
			<ContactIntro/>
		</main>
	</Layout>
)

export default Company_page

const Container = styled.div`
	margin: 8.5rem 0; 
	${ mixin.baseWidthSet }
	.table + .table{
		margin-top: 8rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: 5rem;
		margin-bottom: 6rem;
		.table + .table{
			margin-top: 3rem;
		}
	`}
`;

export const query = graphql`
	query companyImgs_en{
		heroImg: file( relativePath: { eq:"company/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	}
`
